@import './../colors/colors.scss';
@import '../../../assets/scss/variable.scss';


.custom__status{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  height: 20px;
  border-radius: 16px;
  font-family: $PPINS;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.015em;
  text-align: center;
  color: $greyColor-100;
  margin: auto;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
  flex-wrap: nowrap;
  &.active, &.entered{
    background-color: $blueColor-20;
  }
  &.time-exceeded, &.completed-by-system{
    background-color: $redStatusColor-20;
  }
  &.displacement{
    background-color: $yellowStatusColor-20;
  }
  &.completed, &.left{
    background-color: $greenStatusColor-30;
  }
}