//Definitions Source:
// https://www.figma.com/design/nZEfdJ8pN0ILY9cAkO3DSG/Munily-Design-System?node-id=2111-30801&node-type=frame&t=KB34ebrXVf8zgLD2-0

//PRIMARY COLORS >>>>>>>>>>>>>>>>>>>
//For CTA’s, buttons, links, headers, icons, and progress bars.
  //BLUES>>>
    $blueColor-100 : #1E5EA4;   // primary/blue/b-100
    $blueColor-90 : #2367B3;    // primary/blue/b-100
    $blueColor-80 : #2870C1;    // primary/blue/b-100
    $blueColor-70 : #2E78D0;    // primary/blue/b-100
    $blueColor-60 : #3381DE;    // primary/blue/b-100
    $blueColor-50 : #388AED;    // primary/blue/b-100
    $blueColor-40 : #66A5F1;    // primary/blue/b-100
    $blueColor-30 : #95C1F6;    // primary/blue/b-100
    $blueColor-20 : #C3DCFA;    // primary/blue/b-100
    $blueColor-10 : #DCEEFF;    // primary/blue/b-100
  //BLUES<<<
//PRIMARY COLORS >>>>>>>>>>>>>>>>>>>

//NEUTRALS >>>>>>>>>>>>>>>>>>>>>
//For titles and paragraphs texts. Also in separator lines and in the borders of text inputs.
  //LIGHT GREYS >>>
    $greyColor-100: #1E293B;  // secundary/s-100
    $greyColor-90: #2A3548;   // secundary/s-90
    $greyColor-80: #374357;   // secundary/s-80
    $greyColor-70: #465267;   // secundary/s-70
    $greyColor-60: #586479;   // secundary/s-60
    $greyColor-50: #6B778C;   // secundary/s-50
    $greyColor-40: #7F8BA1;   // secundary/s-40
    $greyColor-30: #94A2B7;   // secundary/s-30
    $greyColor-20: #CAD4E0;   // secundary/s-20
    $greyColor-10: #E2E8F1;   // secundary/s-10
  //LIGHT GREYS <<<
  //DARK GREYS >>>
    $darkGreyColor-100: #000000; // neutral/dark/d-100
    $darkGreyColor-90: #121317;  // neutral/dark/d-90
    $darkGreyColor-80: #26262B; // neutral/dark/d-80
    $darkGreyColor-70: #3C3B41; // neutral/dark/d-70
    $darkGreyColor-60: #565459; // neutral/dark/d-60
    $darkGreyColor-50: #726F75; // neutral/dark/d-50
    $darkGreyColor-40: #8F8C92; // neutral/dark/d-40
    $darkGreyColor-30: #B2AFB3; // neutral/dark/d-30
    $darkGreyColor-20: #D7D5D8; // neutral/dark/d-20
    $darkGreyColor-10: #F6F6F6; // neutral/dark/d-10
  //DARK GREYS <<<
  $neutralWhite: #FFFFFF;
  $neutralBalck: $darkGreyColor-100;
//NEUTRALS <<<<<<<<<<<<<<<<<<<<<<

//SYSTEM STATUS >>>>>>>>>>>>>>>>>>
//Use it on notifications, errors, wanrings, and success mesagges on modals assets.
  //GREENS - SUCCESS >>>
    $greenStatusColor-100:#369752; // system-status/green/g-100
    $greenStatusColor-90: #38A262; // system-status/green/g-90
    $greenStatusColor-80: #3BAD72; // system-status/green/g-80
    $greenStatusColor-70: #3DB881; // system-status/green/g-70
    $greenStatusColor-60: #40C391; // system-status/green/g-60
    $greenStatusColor-50: #42CEA1; // system-status/green/g-50
    $greenStatusColor-40: #71DBB9; // system-status/green/g-40
    $greenStatusColor-30: #A0E7D0; // system-status/green/g-30
    $greenStatusColor-20: #BDEEDE; // system-status/green/g-20
    $greenStatusColor-10: #D9F5EC; // system-status/green/g-10
  //GREENS - SUCCESS <<<

  //YELLOWS  - WARNING >>>
    $yellowStatusColor-100: #BD6F12; //system-status/yellow/y-100
    $yellowStatusColor-90: #D88526; //system-status/yellow/y-90
    $yellowStatusColor-80: #F29B3A; //system-status/yellow/y-80
    $yellowStatusColor-70: #FCBB5B; //system-status/yellow/y-70
    $yellowStatusColor-60: #FCC26B; //system-status/yellow/y-60
    $yellowStatusColor-50: #FCC97C; //system-status/yellow/y-50
    $yellowStatusColor-40: #FDCF8C; //system-status/yellow/y-40
    $yellowStatusColor-30: #FDD69D; //system-status/yellow/y-30
    $yellowStatusColor-20: #FDDDAD; //system-status/yellow/y-20
    $yellowStatusColor-10: #FEE8C8; //system-status/yellow/y-10
  //YELLOWS  - WARNING <<<

  //REDS  - ERROR >>>
    $redStatusColor-100: #BB2A3E; //system-status/red/r-100
    $redStatusColor-90: #C93749; //system-status/red/r-90
    $redStatusColor-80: #D74553; //system-status/red/r-80
    $redStatusColor-70: #E5525E; //system-status/red/r-70
    $redStatusColor-60: #F35F68; //system-status/red/r-60
    $redStatusColor-50: #F9787F; //system-status/red/r-50
    $redStatusColor-40: #FF9196; //system-status/red/r-40
    $redStatusColor-30: #FEB3B6; //system-status/red/r-30
    $redStatusColor-20: #FFCED0; //system-status/red/r-20
    $redStatusColor-10: #FFF1F1; //system-status/red/r-10
  //REDS  - ERROR <<<
//SYSTEM STATUS <<<<<<<<<<<<<<<<<


