// ngx-datatable - Clase principal que envuelve toda la tabla.
// datatable-header - Contenedor del encabezado de la tabla.
// datatable-row-center - Filas del contenido.
// datatable-row-group - Grupos de filas.
// datatable-footer - Contenedor del pie de la tabla.
// datatable-body-cell - Cada celda de contenido.
// datatable-header-cell - Cada celda del encabezado.
@import './../../../assets/scss/variable.scss';

ngx-datatable.material.fluid-row-ngx-table {
  background-color: #f8f9fa;
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)!important;
  //HEADER
  .datatable-header {
    height: 56px;
    background: #FFFFFF;

    .datatable-header-inner{
      height: inherit;
    }
    .datatable-header-cell{
      align-items: center;
      display: flex;
      justify-content: center;
      font-family: $PPINSL;
      font-size: 14px;
      font-weight: 600;
      line-height: 16.9px;
      letter-spacing: 0.001em;
      color: #1E293B;
      &.padding-left-none{
        justify-content: start!important;
      }
    }
  }
  //BODY
  .datatable-row-wrapper{
    background: #FFFFFF;
    .datatable-body-row {
      background: #ffffff;
      border-bottom: 1px solid #B2AFB3; /* Aplica el borde a las filas individuales */
      .datatable-body-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &.left-align{
          justify-content: flex-start;
          text-align: start;
        }
        .buttons{
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          justify-content: center;
          .btn-action{
            display: flex;
            align-items: center;
            border: none;
            background: transparent;
            height: -webkit-fill-available;
            
          }
        }
      }
    }
  }
  //FOOTER
  .datatable-footer{
    .custom-footer{
      width: inherit;
      height: inherit;
      display: flex;
      gap: 32px;
      padding: 0 24px;
      align-items: center;
      justify-content: flex-start;
      div.footer-item{ //Total count
        display: flex;
        height: inherit;
        align-items: center;
        color:#726F75;
        font-family: $PPINS;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.0015em;        
        line-height: 16.9px;
        &:nth-child(1), &:nth-child(1) strong{ //total count
          font-size: 15px;
          font-weight: 600;
          color: #1E293B;
        }
        &:nth-child(2){ //select size
          line-height: 19.5px;
          gap: 8px;
          select{
            width: 53px;
            height: 32px;
            border: 1px solid #D7D5D8;
            border-radius: 8px;
            padding: 2px;
          }
        }
        &:nth-child(3){ //datatable-pager
          .datatable-pager{
            li a{
              color:#2E78D0!important;
              font-family: $PPINS;
              font-size: 15px;
              font-weight: 500;
              line-height: 19.5px;
              letter-spacing: 0.0015em;
              text-align: center;
            }
          }
        }
        
      }
    }
  }

  .datatable-row-wrapper{
    padding: 0px;
  }
  
  *::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: #E0E0E0;
    border-radius: 1em;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

