@import '../fonts/fonts.scss';

.label-1 {
  font-family: $munilyFamily;
  color: $munilyFontColorBold;
  font-size: $munilyFontParrSize4;
  font-weight: 700;
}

.label-blue-1 {
  font-family: $munilyFamily;
  color: $munilyBlueColor;
  font-size: $munilyFontParrSize4;
  font-weight: 700;
}
